import React from "react";
import ReactMarkdown from "react-markdown";

interface ArticleBlockProps {
  theme: {
    classNames: string;
  };
  markdown: string;
}

const ArticleBlock = ({
  theme: { classNames },
  markdown,
}: ArticleBlockProps) => {
  return (
    <article className={`container mx-auto prose ${classNames}`}>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </article>
  );
};

export default ArticleBlock;
